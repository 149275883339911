:root {}

.prod {
  // Meta / Price / Form
  //------------------------
  &__meta {
    grid-column: 7 / 8;
    align-self: flex-start;

    h2 { color: var(--color-primary); }

    @include breakpoint(sm) {
      position: sticky;
      top: var(--space-xl);
      margin-bottom: var(--space-md);
    }

    @include breakpoint(md, 'not all') {
      grid-column: 5 / -2;
    }

    @include breakpoint(sm, 'not all') {
      grid-column: 2 / -2;
      grid-row: 1;
      margin-bottom: var(--space-xl);

      form { display: none; }
    }
  }
}
