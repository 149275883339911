// if using Vue Formulate...
// @import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';

:root {
    // forms
    --form-checkbox-border: var(--color-black);
    --form-element-border: var(--color-contrast-low);
    --form-element-border-focus: var(--color-secondary);
    --form-element-border-error: var(--color-error);
    --form-element-icon: var(--color-black);
    --form-element-bg: var(--white);
    --form-label: var(--color-black);
    --form-placeholder: var(--color-contrast-low);
    --form-field-height: 3.2em;

    // range sliders
    --track-width: 100%;
    --track-height: 4px;
    --track-ball: 1.5em;
    --track-color: var(--color-contrast-lower);
    --track-thumb-color: var(--color-black);

    --form-error-bg: var(--color-error-light);
    --form-error-color: var(--color-error);
}

%checkboxBase {
    --form-element-border: var(--grey-4);
    @include flex;
    $s: 1em;
    pointer-events: none;
    width: $s;
    height: $s;
    border: 1px solid var(--form-element-border);
    border-radius: var(--radius-lg);
    transition: 0.12s ease-out;
    z-index: 5;
}

%checkboxTick {
    opacity: 0;
    transition: 0.1s ease-out;
    stroke-width: 0.7em;
    fill: none;
    margin: auto;
    stroke: var(--color-primary);
    width: 0.6em;
    height: 0.6em;
    stroke-dashoffset: 1;
    stroke-dasharray: 1;
}

%checkboxTickActive {
    opacity: 1;
    stroke-dashoffset: 0;
}

[type='text'],
[type='email'],
[type='password'],
[type='number'],
[type='tel'],
[type='search'],
[type='url'],
textarea {
    @include input-placeholder {
        color: var(--form-placeholder);
    }
    font-family: var(--font-primary);
    outline: none;
    display: block;
    width: 100%;
    border: 1px solid var(--form-element-border);
    border-radius: var(--radius-sm);
    padding: var(--space-sm);
    height: var(--form-field-height);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: 0.2s var(--ease-out);

    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:focus {
        border-color: var(--form-element-border-focus);
    }
}

textarea{
    min-height: 5em;
}

select {
    padding: var(--space-sm);
    height: var(--form-field-height);
}

[type='radio'],
[type='checkbox'] {
}

[type='hidden'] {
    display: none;
}

label {
    display: inline-block;
    font-weight: var(--font-medium);
    margin-bottom: calc(var(--space-sm) / 2);

    [data-is-showing-errors][data-has-errors] & {
        color: var(--form-error-bg);
    }
}

// .form,
// form {
//     margin-bottom: var(--space-xl);
// }

.form {
    width: 100%;

    &__cb-label {
        width: calc(100% - 1em);
        font-weight: var(--font-regular);
        margin-top: 2px;

        &--selected {
            font-weight: var(--font-bold);
            color: var(--color-black);
        }
    }

    &__group {
        margin: 1em 0;
    }

    &__group--checkbox label {
        cursor: pointer;
        position: relative;
        width: 100%;

        input {
            position: absolute;
            left: -9999px;
            opacity: 0;
            width: 0;
            height: 0;
        }

        span {
            @include flex;
            align-items: center;
            font-weight: var(--font-regular);

            &::before {
                @extend %checkboxBase;

            }
        }

        input:checked + span::before {
            border-color: var(--color-primary);
            background-color: var(--color-primary);
        }
    }

    &__label {
        @include flexMiddle;
        width: 100%;

        label,
        [class^='add-new'] & label {
            width: auto;
        }
        label + * {
            margin-left: auto;
        }
    }
}

.formkit {
    $fk: &;

    &-outer {
        margin: var(--space-md) 0;

        &[data-type=checkbox] label {
            display: flex;
            gap: var(--space-sm);
            cursor: pointer;
        }
    }

    &-decorator { @extend %checkboxBase; }

    &-input[type=checkbox] {
        appearance: none;
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        left: -999em;

        + #{$fk}-decorator {
            border-radius: var(--radius-sm);

            &::after {
                @extend %checkboxTick;
                content: '\2714';
                font-size: 1.2em;
                font-weight: var(--font-black);
                transform: scale(0) translate(1px, -0.3em);
                color: var(--color-primary);
                transition: 0.2s var(--ease-out);
            }
        }

        &:checked { color: black; }
        &:checked + #{$fk}-decorator {
            &::after {
                @extend %checkboxTickActive;
                transform: scale(1) translate(1px, -0.3em);
            }
        }
    }

    // validation
    &-message {
        &[data-message-type=validation] {
            color: var(--form-error-color);
            font-size: var(--text-sm);
            margin-top: var(--space-xs);
        }
        &[data-message-type=ui] {
            color: var(--form-error-color);
            font-weight: var(--font-bold);
            margin-top: var(--space-xs);
        }
    }
}
