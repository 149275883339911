.mouse {
    display: block;
    height: 35px;
    margin: auto;

    @include breakpoint(sm, 'not all') {
        display: none;
    }

    &, * { stroke-width: 1px; }

    #base { opacity: .5; }

    #wheel { display: none; }

    #wheel-circle {
        transform-origin: 50% 20%;
        transform: translate3d(0px, 0px, 0);
        animation: mousewheel 6s ease-in-out infinite;
        opacity: 1;
    }

    #clip-path {
        transform: translate3d(0px, 0px, 0);
        animation: highlight 6s ease-in-out infinite;
    }
}

@keyframes highlight {
    0%, 10% { transform: translate3d(0px, -120%, 0); }
    10% { transform: translate3d(0px, 0%, 0); }
    30%, 100% { transform: translate3d(0px, 120%, 0); }
}

@keyframes mousewheel {
    0% { transform: translate3d(0px, 0px, 0) scale(1); }
    10% { transform: translate3d(0px, 20px, 0) scale(0); }
    10%, 16% { opacity: 0; }
    16%, 20% { transform: translate3d(0px, 0px, 0) scale(1); }
    25% { opacity: 1; }
}
