.gc {
  display: grid;
  grid-template-columns: 0.25fr 0.75fr 1fr repeat(4, 0.25fr) repeat(2, 0.5fr) repeat(4, 0.25fr);
  grid-column: 1 / -1;
  margin: var(--space-xl) 0;

  &:last-of-type { margin-bottom: var(--space-xxxl); }

  article {
      grid-column: var(--gc-content-col, 2 / 8);
      grid-row: 1;
  }

  figure {
      grid-column: var(--gc-img-col);
      aspect-ratio: 16 / 9;
    //   overflow: hidden;
      grid-row: 1;

      img {
        //   object-fit: cover;
        //   width: 100%;
        //   height: 100%;
          max-width: 100%;
      }
  }

  &--intro { --gc-content-col: 3 / 9; }
  &--imgLeft {
      --gc-content-col: 8 / 11;
      --gc-img-col: 2 / 7;
  }

  &--imgRight {
      --gc-content-col: 3 / 5;
      --gc-img-col: 6 / 13;
  }

  &--imgLeft,
  &--imgRight {
      article { margin-top: var(--space-lg); }
  }
}
