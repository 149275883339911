.p-img {

  &__images{
    grid-column: 1 / 6;
    margin-bottom: var(--space-xl);

    @include breakpoint(md, 'not all') {
      grid-column: 1 / 4;
    }

    @include breakpoint(sm, 'not all') {
      grid-column: 2 / -2;
    }

    img {
      @include imgCover;
      max-width: 100%;
      grid-column: 1 / -1;
    }
  }

  &__img {
    $ratio: 3 / 2;
    display: grid;
    grid-template-columns: 2.75fr 0.25fr;
    align-items: flex-end;
    grid-template-rows: min-content;

    @include breakpoint(sm, 'not all') {
      grid-template-columns: 2.5fr 0.5fr;
    }

    &, img { aspect-ratio: $ratio; }

    img { grid-row: 1 / span 3; }
  }

  &__arr {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 2;
    grid-row: 2;
    aspect-ratio: 1 / 1;
    background: white;
    color: var(--color-primary);
    font-size: 1.25em;
    font-weight: var(--font-black);
    transition: 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);

    &:hover {
      background: var(--color-secondary);
      color: var(--color-white);
      transition: 0.1s cubic-bezier(0.215, 0.610, 0.355, 1);
    }

    &--left:hover {
      padding-right: var(--space-sm);
    }

    &--right {
      grid-row: 3;
      &:hover {
        padding-left: var(--space-sm);
      }
    }
  }

  &__thumbs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--space-lg);
    margin-top: var(--space-lg);

    @include breakpoint(md, 'not all') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(sm, 'not all') {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: var(--space-sm);
      margin-top: var(--space-md);
    }

    button {
      aspect-ratio: 9 / 7;
      padding: 0;
    }
  }
}
