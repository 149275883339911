:root {
  --l-display: grid;
  --l-grid-cols: repeat(4, 0.25fr) repeat(3, 1fr) repeat(4, 0.25fr);
}

// Layouts
[class*=layout--] {
  display: var(--l-display);
  grid-template-columns: var(--l-grid-cols);

  .p-header { grid-column: 1 / -1; }
}

.layout {
  // Stock Archive
  &--stock {

    @include breakpoint(md, 'not all') {
      --l-grid-cols: repeat(4, 0.25fr) 0.25fr 0.75fr repeat(2, 1fr) repeat(4, 0.25fr);
    }
    @include breakpoint(sm, 'not all') {
      --l-grid-cols: repeat(4, 0.25fr) 0.25fr 0.75fr repeat(4, 0.25fr);
    }

    .p-header { grid-column: 1 / -1; margin-bottom: var(--space-xl); }
    .s-filters {
      display: none; // TODO Removed temporarily as it makes more sense not having filters with the limited stock
      grid-column: 2 / span 3;

      @include breakpoint(md, 'not all') { grid-column: 2 / span 4; }
    }
    .s-stock {
      display: grid;
      grid-template-columns: 1fr repeat(4, 0.25fr) 1fr repeat(4, 0.25fr);
      // grid-column: 5 / -1;
      grid-column: 2 / -1;
      row-gap: var(--space-xxl);
      margin-bottom: var(--space-xxl);

      @include breakpoint(md, 'not all') { grid-column: 2 / -1; }
      @include breakpoint(sm, 'not all') {
        grid-column: 2 / -2;
        grid-template-columns: 1fr;
      }
    }
  }

  // Boat Page
  &--boat {
    .p-boat {
      &__content {
        display: grid;
        grid-column: 1 / -1;
        grid-template-columns: 0.25fr 0.75fr 1fr 0.75fr 0.25fr 0.25fr 1.5fr 0.25fr;

        @include breakpoint(md, 'not all') {
          grid-template-columns: 0.25fr 0.75fr 1fr 0.25fr 0.75fr 0.75fr 0.25fr;
        }

        @include breakpoint(sm, 'not all') {
          grid-template-columns: var(--grid-columns);
        }
      }

      // Content / Description
      //------------------------
      &__info,
      &__extras {
        grid-column: 2 / 6;
        margin: var(--space-lg) 0;

        @include breakpoint(md, 'not all') {
          grid-column: 2 / 4;
        }

        @include breakpoint(sm, 'not all') {
          grid-column: 2 / -2;
        }

        h2 { font-size: 3.5vmax; }
      }

      &__info {

        @include breakpoint(md) {
          display: grid;
          grid-template-columns: 1.5fr 0.25fr 1fr;

          article:nth-child(even) { grid-column: 3; }
        }

        @include breakpoint(md, 'not all') {
          article { margin-bottom: var(--space-xl); }
        }
      }

      &__extras {
        ul {
          display: grid;
          grid-template-columns: 1fr  1fr 1.14fr;
          margin: var(--space-md) 0;

          @include breakpoint(md, 'not all') {
            grid-template-columns: 1fr 1fr;
          }

          li {
            padding-right: var(--space-lg);
            margin-bottom: var(--space-xs);
          }
        }
      }
    } // p-boat
  }

  &--generic {
    &.p-posts {
      .p-header__title .pre {
        font-size: 1.3rem;
        color: var(--color-primary-lighter);
        transform: translateY(-0.25rem);
        opacity: 0.8;
      }
    }
  }
}

// Contact
.section {
  &__contact {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: var(--space-lg);
    grid-column: 2 / 6;
    padding-right: var(--space-xxl);
    word-break: break-word;
    margin-bottom: var(--space-xl);
    align-items: flex-start;

    @include breakpoint(md, 'not all') {
      padding-right: var(--space-xl);
      grid-column: 2 / 6;
      grid-template-columns: minmax(0, 1fr);

      article:not(:first-of-type) h3 { margin-top: var(--space-lg); }
    }

    @include breakpoint(sm, 'not all') {
      grid-column: 2 / -2;
      padding-right: 0;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-rows: auto auto auto;
    }

    @include breakpoint(xs, 'not all') {
      grid-column: 2 / -2;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: auto;
    }

    form {
      grid-column: 1 / -1;
      margin-bottom: var(--space-lg);

      @include breakpoint(sm, 'not all') {
        grid-column: 1;
        grid-row: span 3;

        ~ * { grid-column: 2; }
        + article { margin-top: var(--space-lg); }
      }

      @include breakpoint(xs, 'not all') {
        grid-row: 1;
        ~ * { grid-column: 1; }
        + article { margin-top: 0; }
      }
    }
    h3 { font-size: 1rem; }
  }

  &__map {
    --map-offset: 2.7vw;

    @include breakpoint(sm) {
      grid-column: 6 / -1;
      position: relative;
      z-index: 10;
      height: calc(100% + var(--map-offset));
      transform: translateY(calc(var(--map-offset) * -1));
      clip-path: polygon(0 0, 100% var(--map-offset), 100% 100%, 0 100%);

      &:after {
        content: '';
        display: block;
        width: var(--space-lg);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(0,0,0,0.1), rgba(0,0,0,0.0));
      }
    }


    @include breakpoint(sm, 'not all') {
      grid-column: 1 / -1;
      height: 50vw;
    }
  }
}

.content.insurance {
  grid-column: 5 / -5;
  padding-bottom: var(--space-xxl);
  * { max-width: 65ch; }
  li { margin-bottom: var(--space-sm); }
  p ~ :is(h1, h2, h3, h4, h5, h6) { margin-top: var(--space-md); }

  @include breakpoint(md, 'not all') { grid-column: 3/-3; }
}
