:root,
[data-theme='default'] {
    // Colours
    $black: #020202;
    $charcoal: #1a1a1a;
    $dark-grey: #333333;
    $white: #fff;

    --light-green: #b0bb24;

    // shades - generated using chroma.js - 12 steps
    --black: #000;
    --grey-10: #2e2e31;
    --grey-8: #4d4d4d;
    --grey-7: #7b7a7d;
    --grey-6: #828282;
    --grey-5: #999999;
    --grey-4: #a5a5a6;
    --grey-3: #d1d0d2;
    --grey-2: #e6e6e6;
    --grey-1: #f6f6f6;
    --white: white;

    // main
    @include defineColorHSL(--color-primary-darker, 24, 100%, 35%);
    @include defineColorHSL(--color-primary-dark, 27, 100%, 40%);
    @include defineColorHSL(--color-primary, 27, 100%, 50%);
    @include defineColorHSL(--color-primary-light, 27, 100%, 60%);
    @include defineColorHSL(--color-primary-lighter, 27, 100%, 70%);

    @include defineColorHSL(--color-secondary-darker, 276, 100%, 16%);
    @include defineColorHSL(--color-secondary-dark, 276, 71%, 27%);
    @include defineColorHSL(--color-secondary, 276, 51%, 36%);
    @include defineColorHSL(--color-secondary-light, 276, 54%, 46%);
    @include defineColorHSL(--color-secondary-lighter, 276, 51%, 77%);

    @include defineColorHSL(--color-black, 0, 0%, 0%);
    @include defineColorHSL(--color-white, 0, 0%, 100%);

    // feedback
    @include defineColorHSL(--color-warning-darker, 46, 100%, 47%);
    @include defineColorHSL(--color-warning-dark, 46, 100%, 50%);
    @include defineColorHSL(--color-warning, 46, 100%, 61%);
    @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
    @include defineColorHSL(--color-warning-lighter, 46, 100%, 80%);

    @include defineColorHSL(--color-success-darker, 94, 48%, 42%);
    @include defineColorHSL(--color-success-dark, 94, 48%, 48%);
    @include defineColorHSL(--color-success, 94, 48%, 56%);
    @include defineColorHSL(--color-success-light, 94, 48%, 65%);
    @include defineColorHSL(--color-success-lighter, 94, 48%, 90%);

    @include defineColorHSL(--color-error-darker, 349, 75%, 36%);
    @include defineColorHSL(--color-error-dark, 349, 75%, 44%);
    @include defineColorHSL(--color-error, 349, 75%, 51%);
    @include defineColorHSL(--color-error-light, 349, 75%, 59%);
    @include defineColorHSL(--color-error-lighter, 349, 75%, 90%);

    // color contrasts
    @include defineColorHSL(--color-bg, 0, 0%, 100%);
    @include defineColorHSL(--color-contrast-lower, 0, 0%, 95%);
    @include defineColorHSL(--color-contrast-low, 240, 1%, 83%);
    @include defineColorHSL(--color-contrast-medium, 240, 1%, 48%);
    @include defineColorHSL(--color-contrast-high, 240, 4%, 20%);
    @include defineColorHSL(--color-contrast-higher, 240, 8%, 12%);

    // typography
    --color-text: var(--color-contrast-medium);
    --color-text-heading: var(--black);
    --color-text-label: var(--black);
    --color-text-subtle: var(--color-contrast-low);
    --color-link: var(--color-primary);
    --color-link-visited: var(--color-primary-dark);
    --color-link-hover: var(--color-secondary);
    --color-mark: var(--color-secondary);
    --color-blockquote-border: var(--grey-2);

    // sections
    --bg-grey: var(--color-contrast-lower);

    // footer
    --color-footer-bg: var(--grey-10);
    --color-footer-links: var(--color-white);
    --color-footer-divider: var(--grey-8);

    // border
    --color-border: var(--grey-3);

    // icons
    --color-icon-primary: var(--grey-4);
    --color-icon-secondary: inherit;
}
