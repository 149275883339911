:root {}

.s-stock {
  &__item {
    @include breakpoint(sm) {
      grid-column: span 4;

      &:nth-child(even) {
        grid-column: 6 / span 4;
      }
    }
  }

  &__actions {
    display: flex;
    grid-column: 1 / -2;
    justify-content: center;
  }
}
