:root {
  // font family
  --font-primary: 'Gotham', helvetica, arial, sans-serif;
  --font-secondary: 'Forza', Gotham, helvetica, arial, sans-serif;

  // weights:
  --font-regular: normal;
  --font-medium: 500;
  --font-bold: bold;
  --font-black: 900;

  // body font size
  --text-base-size: 1em;

  // type scale
  --text-scale-ratio: 1.3;
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * 1.2);
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio) * 2);

  // line-height
  --body-line-height: 1.5;
  --heading-line-height: .9;
  --intro-line-height: 1.25;

  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 1;
}


body {
    -webkit-font-smoothing: antialiased;
    font-weight: var(--font-regular);
    font-size: var(--text-base-size);
    font-family: var(--font-primary);
    color: var(--color-text);
}

h1, h2, h3, h4, form legend {
    font-family: var(--font-secondary);
    color: var(--color-text-heading);
    margin-bottom: var(--space-xxs);
    line-height: var(--heading-line-height);
    font-weight: var(--font-bold);
}

h2, .title {
  font-size: 4.5vmax;
  &__alt {
    color: var(--color-contrast-medium);
  }
}

h1, h2, h3, h4, h5 { font-weight: var(--font-black); }

// text size
.text--xxxl { font-size: var(--text-xxxl); }
h1, .text--xxl { font-size: var(--text-xxxxl); }
// h2, .text--xl { font-size: var(--text-xxxl); }
h3, .text--lg { font-size: var(--text-lg); }
h4, .text--md { font-size: var(--text-md); }
.text--sm, small { font-size: var(--text-sm); }
.text--xs { font-size: var(--text-xs); }

p {
  line-height: var(--body-line-height);
  margin-bottom: var(--space-sm);

  // + p { margin-top: var(--space-sm); }
}

a {
  color: var(--color-link);
  transition: .2s var(--ease-out);
  text-decoration: none;

  &:visited { color: var(--color-link-visited); }
  &:hover{ color: var(--color-link-hover); }

  &.bold {
    font-weight: var(--font-bold);
  }
}

b, strong { font-weight: bold; }

.content {
  ul, ol {
    list-style: initial;

    li{
      line-height: var(--body-line-height);
      margin-left: 1em;

      &::marker {
        color: var(--color-primary);
      }
    }
  }

  em { font-style: italic; }
  u { text-decoration: underline; }
}

dl {
  div {
    display: flex;
    gap: var(--space-xs);
    margin: var(--space-md) 0;
  }

  dt {
    font-weight: var(--font-bold);
    color: black;
  }
}

// utility classes
.truncate {
    // truncate text if it exceeds parent
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
