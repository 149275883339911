:root {
    --grid-gap: 10px;
    --grid-columns: .5fr .5fr repeat(8, 1fr) .5fr .5fr;
    --grid-gap-horizontal: var(--grid-gap); // for specifically controlled gaps
    --grid-gap-vertical: var(--grid-gap); // for specifically controlled gaps

    --grid-base-cols: repeat(5, minmax(0, 1fr));

    @include breakpoint(md, 'not all') {
        --grid-columns: .5fr .5fr repeat(6, 1fr) .5fr .5fr;
        --grid-base-cols: repeat(4, minmax(0, 1fr));
    }

    @include breakpoint(sm, 'not all') {
        --grid-columns: .5fr .5fr repeat(4, 1fr) .5fr .5fr;
        --grid-base-cols: repeat(3, minmax(0, 1fr));
    }

    @include breakpoint(xs, 'not all') {
        --grid-columns: .5fr .5fr repeat(3, 1fr) .5fr .5fr;
        --grid-base-cols: repeat(3, minmax(0, 1fr));
    }

}
