:root {
    --header-height: 150px;
}

.header {
  display: grid;
  grid-template-columns: var(--grid-columns);
  align-items: center;
  // position: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: var(--space-md) 0;
  z-index: 500;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 80%);

  &__logo {
      grid-column: 2 / 4;
      max-width: 275px;
  }
}

// Page Headers
.p-header {
  $this: &;

  display: grid;
  grid-template-columns: var(--grid-columns);
  // grid-template-rows: clamp(150px, 100vw, 6vw) 1fr 2.5vw auto;
  width: 100%;
  height: clamp(200px, 100vw, 25vw);
  position: relative;

  &--margined {
    margin-bottom: clamp(3.5em, 100%, 8vw);
  }

  &__inner,
  &__bg {
    height: 100%;
    position: relative;
  }

  &__inner {
    overflow: hidden;
    grid-column: 1 / -1;
    // grid-row: 1 / 4;

    &::after {
      @include pseudo;
      // background: white;
      width: 100%;
      bottom: 0;
      height: 30%;
      border-bottom: 4.5vw solid white;
      border-right: 100vw solid transparent;
    }
  }

  &__bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: grayscale(1);
    }

    &::before,
    &::after {
      @include pseudo;
      @include positionAbsolute;
      background: black;
      z-index: 1;
    }

    &::before {
      mix-blend-mode: overlay;
      opacity: .4;
    }

    &::after {
      opacity: .12;
      // this one will make it completely black if it's not supported, where the first one wont.
      @supports (mix-blend-mode: soft-light) {
          opacity: .9;
          mix-blend-mode: soft-light;
      }
    }
  }

  &__overlay-text {
    @include breakpoint(xs, 'not all') { display: none; }
    font-family: var(--font-secondary);
    font-size: clamp(2em, 97vw, 17vw);
    display: block;
    position: absolute;
    top: -0.05em;
    font-weight: var(--font-black);
    color: white;
    line-height: .8;
    text-transform: uppercase;
    /* font-variant: unicase; */
    opacity: .35;

    .p-boat & {
      font-size: clamp(1.5em, 97vw, 21vw);
      margin-left: -0.05em;
      top: auto;
      bottom: 2vw;
    }
  }

  &__title {
    font-size: var(--text-xxl);
    grid-column: 2;
    position: absolute;
    bottom: 2.75vw;
    margin-bottom: 0;
    z-index: 1;
    color: white;
    text-transform: uppercase;
    line-height: 0.8;

    .pre,
    .post { display: block; }

    .pre {
      font-weight: var(--font-medium);
    }

    .post {
      color: black;
      position: absolute;
    }

    @include breakpoint(xs) {
      max-width: 730px;
      font-size: calc(32px + 20 * ((100vw - 512px) / (800 - 512)));
    }

    // @include breakpoint(lg) {
    //   max-width: 50vw;
    //   font-size: 7em;
    // }
    // font-size: clamp(1em, 97vw, var(--text-xxxxl));
    // width: clamp(300px, 100vw, 50vw);
  }
}
