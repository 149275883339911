:root {
    --nav-link: var(--color-black);
    --nav-link-hover: var(--color-primary);
    --nav-link-active: var(--color-primary);
}

.nav {
    grid-column: 5 / -2;
    margin-left: auto;

    @include breakpoint(sm, 'not all') {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        background: rgba(0, 0, 0, 0.9);
        opacity: 0;
        transform: translateX(-100%);
        transition: 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);

        &--open {
            opacity: 1;
            transform: translateX(0);
        }
    }

    &__list {
        @include flexMiddle;

        @include breakpoint(sm, 'not all') {
            width: 100%;
            height: 100%;
            align-content: flex-start;
            gap: var(--space-xs);
            margin-top: var(--space-xxl);
        }
    }

    &__item + &__item {
        @include breakpoint(sm) { margin-left: var(--space-sm); }
    }

    &__item {
        &, a {
            color: white;
            @include breakpoint(sm, 'not all') {
                font-weight: var(--font-medium);
                font-size: 1.2rem;
                display: block;
            }
        }

        a::after {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            background: var(--color-primary);
            transform: translateY(0);
            opacity: 0;
            transition: 0.5s var(--ease-out);

        }

        .router-link-active,
        .router-link-exact-active {
            &::after {
                height: 2px;
                transform: translateY(3px);
                opacity: 1;

                @include breakpoint(sm, 'not all') {
                    height: 4px;
                    transform: translateY(0);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    left: calc(-100% - 0.5em);
                }
            }
        }

        @include breakpoint(sm, 'not all') {
            width: 100%;
            padding-left: var(--space-lg);

            a { padding: var(--space-sm) 0; position: relative; }
        }
    }

    &__toggle {
        grid-column: -3;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 1.6rem;
        padding: var(--space-sm) 0;

        @include breakpoint(sm) {
            display: none;
        }
    }

    &__toggle-line {
        display: block;
        width: 100%;
        height: 2px;
        background: var(--white);
        opacity: 1;
        transition: 0.2s cubic-bezier(0.23, 1, 0.320, 1);
        transform: translateY(0) rotate(0);

        .nav__toggle--open & {
            transform: rotate(270deg);
            &:nth-child(2) { opacity: 0; }
            &:first-child { transform: translateY(6px) rotate(215deg); }
            &:last-child { transform: translateY(-6px) rotate(-215deg); }
        }
    }
}
