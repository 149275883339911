:root {
}

.slider {
    &__arrows {
        button { text-transform: uppercase; }

        i {
            font-size: 1.25em;
            color: var(--color-primary);
            transition: 0.2s var(--ease-out);
        }
        button:hover {
            color: var(--color-secondary);
            i { color: currentColor; }
        }
    }
}

.slider--boat {
    display: grid;
    grid-template-columns: repeat(4, 0.25fr) 1fr 0.5fr repeat(2, 0.25fr) 1fr repeat(4, 0.25fr);
    grid-template-rows: minmax(0px, auto) minmax(30px, auto) minmax(150px, auto) minmax(0, auto) minmax(0, auto);

    .slider {
        &__title {
            grid-column: 2 / 7;
            grid-row: 1 / span 2;
            word-spacing: 100vw;
            align-self: flex-end;
            margin-bottom: 0.8em;
            max-width: 10ch;

            @include breakpoint(md, 'not all') {
                grid-column: 2 / -2;
            }

            span {
                word-spacing: initial;
                display: block;
            }

        }

        &__arrows {
            grid-row: 3;
            grid-column: 1;
            transform: translateX(var(--space-md));

            button {
                display: flex;
                gap: var(--space-md);
                margin-bottom: var(--space-md);
            }

            @include breakpoint (sm, 'not all') {
                grid-column: 1 / -2;
            }
        }

        &__grid-block {
            display: block;
            background: var(--bg-grey);
            position: relative;
            grid-column: 3 / -5;
            z-index: -1;
            grid-row: 2 / span 3;

            @include breakpoint (sm, 'not all') {
                grid-row: 2 / span 2;
                grid-column: 3 / -3;
                height: 50%;
            }
        }

        &__content {
            grid-column: var(--b-c-col, 4 / 7);
            grid-row: 3 / -3;
            margin-bottom: var(--space-xl);
            padding-right: var(--space-xxl);
            line-height: 1.5;

            @include breakpoint(md, 'not all') { --b-c-col: 4 / -6; }
            @include breakpoint (sm, 'not all') { display: none; }
        }

        &__footer {
            grid-row: -2;
            grid-column: var(--b-f-col, 4 / span 3) ;
            margin-top: var(--space-lg);

            @include breakpoint (md, 'not all') { --b-f-col: 3 / span 3; }
            @include breakpoint (sm, 'not all') { --b-f-col: 2 / -2; margin-top: 0; }

            a {
                display: block;
                text-align: left;
                margin-bottom: var(--space-md);
                font-weight: var(--font-bold);

                i { font-size: 0.75rem; margin-left: var(--space-sm);}
            }

            .btn { display: inline-block; }
        }

        &__image {
            grid-column: var(--b-i-col, 6 / -2);
            grid-row: var(--b-i-row, 1 / -1);

            @include breakpoint(md, 'not all') {
                --b-i-col: 7 / -2;
                --b-i-row: 3 / -1;
            }

            @include breakpoint(sm, 'not all') {
                --b-i-col: 5 / -1;
                --b-i-row: 2 / 4;
                max-width: 70vw;
                margin-left: auto;
            }

            @include breakpoint(xxs, 'not all') { max-width: 60vw; }

            img {
                max-width: 100%;
                max-height: 100%;
                display: block;
            }
        }

        &__logo {
            display: flex;
            align-items: flex-end;
            grid-column: -3;
            grid-row: -3 / span 2;
            position: relative;
            opacity: 0.25;

            @include breakpoint(md, 'not all') { display: none; }

            svg {
                $size: 40px;
                position: absolute;
                bottom: var(--space-md);
                left: -5px;
                height: $size;
                transform-origin: 40% 0%;
                transform: rotate(-90deg);
                width: auto;
                max-width: unset;
            }
        }
    }
}

// Product / Stock Slider on homepage
.slider--stock {
    display: grid;
    grid-template-columns: repeat(4, 0.25fr) repeat(4, 1fr);

    @include breakpoint(md, 'not all') { grid-template-columns: repeat(4, 0.25fr) repeat(3, 1fr); }
    @include breakpoint(sm, 'not all') { grid-template-columns: repeat(2, 1fr); padding-right: var(--space-lg); }

    .card {
        grid-row: 2;
        grid-column: var(--stock-column);

        @include breakpoint(sm) {
            grid-row: 1;
        }
    }

    .slider {
        &__title {
            grid-column: 2 / 5;

            @include breakpoint(sm, 'not all') {
                display: flex;
                grid-column: 1 / -1;
                padding-left: var(--space-lg);
            }
        }

        &__arrows {
            display: flex;
            gap: var(--space-sm);
            justify-content: flex-end;
            align-items: flex-end;
            margin-left: auto;

            @include breakpoint(sm, 'not all') { margin-bottom: var(--space-md); }
        }

        &__more {
            grid-column: 1 / -2;
            grid-row: 2;
            margin: var(--space-lg) 0 var(--space-lg) auto;

            @include breakpoint(md, 'not all') {
                grid-column: 1 / -1;
                grid-row: 3;
                margin-right: var(--space-lg);
            }

            @include breakpoint(sm, 'not all') {
                margin-bottom: 0;
            }
        }
    }
}
