:root {
    --b-clip-left: 0%;
    --b-clip-right: 0%;
    --b-trans-x: 0%;
    --b-opacity: 1;
}

.t-slide-right {
    &-enter-to,
    &-leave-from {
        clip-path: inset(0% 0% 0% 0%);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    &-enter-from {
        clip-path: inset(0% 100% 0% 0%);
        transform: translate3d(-10%, 0, 0);
    }
    &-leave-to {
        clip-path: inset(0% 0% 0% 100%);
        transform: translate3d(-10%, 0, 0);
    }

    &-enter-from &-leave-to {
        opacity: 0;
        transform: translate3d(-10%, 0, 0);
    }

    // &-enter-from,
    // &-leave-to {opacity: 0; }

    &-enter-active {
        transition: all 0.5s var(--ease-out);
    }
    &-leave-active {
        transition: all 0.5s var(--ease-in);
    }
}

.t-slide-left {
    &-enter-to,
    &-leave-from {
        clip-path: inset(0% 0% 0% 0%);
        transform: translate3d(0, 0, 0);
    }

    &-enter-from {
        clip-path: inset(0% 0% 0% 100%);
        transform: translate3d(-10%, 0, 0);
    }
    &-leave-to {
        clip-path: inset(0% 100% 0% 0%);
        transform: translate3d(10%, 0, 0);
    }

    &-enter-from &-leave-to {
        opacity: 0;
        transform: translate3d(-10%, 0, 0);
    }

    &-enter-active {
        transition: all 0.5s var(--ease-out);
    }
    &-leave-active {
        transition: all 0.5s var(--ease-in);
    }
}

// Boat slider
.slider--boat .slider {
    &__logo {
        transition: all 0.5s var(--ease-out);
    }

    &__title,
    &__image,
    &__grid-block,
    &__content{
        opacity: var(--b-opacity);
        transition: all 0.5s var(--ease-out);
    }

    &__title,
    &__image,
    &__grid-block,
    &__content {
        clip-path: inset(0% var(--b-clip-right) 0% var(--b-clip-left));
    }

    &__title,
    &__logo {
        transform: translate3d(var(--b-trans-x), 0, 0);
    }

    &__logo { opacity: calc(var(--b-opacity) * 0.2); }

    &__image {
        opacity: var(--b-opacity);
        transform: translate3d(calc(var(--b-trans-x) / 2 * -1), 0, 0);
    }

    &__grid-block {
        clip-path: inset(0% var(--b-clip-left) 0% var(--b-clip-right));
    }
}

.b-slide-left {
    // &-enter-to,
    // &-leave-from {
    // }

    &-enter-from {
        --b-clip-left: 100%;
        --b-trans-x: -50%;
    }

    &-leave-to {
        --b-clip-right: 100%;
        --b-trans-x: 50%;
    }

    &-enter-from,
    &-leave-to {
        --b-opacity: 0;
    }

    &-enter-active {
        transition: all 0.5s var(--ease-out);
    }

    &-leave-active {
        transition: all 0.5s var(--ease-in);
        .slider {
            &__title,
            &__image,
            &__grid-block,
            &__content,
            &__logo {
                transition: all 0.5s var(--ease-in);
            }
        }
    }
}
