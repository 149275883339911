@import "@/assets/scss/mixins/_allMixins.scss";

:root {
  --alert-bg: var(--grey-1);
  --alert-border: var(--grey-2);
  --alert-color: var(--color-primary);
}
.alert {
    background-color: var(--alert-bg);
    color: var(--alert-color);
    padding: var(--space-md);
    margin-bottom: var(--space-lg);
    border-radius: var(--border-radius);
    border: 1px solid var(--alert-border);
    font-size: 0.9rem;

    &--success {
      --alert-bg: var(--color-success-lighter);
      --alert-border: var(--color-success);
      --alert-color: var(--color-success-darker);
    }

    &--error {
      --alert-bg: var(--color-error-lighter);
      --alert-border: var(--color-error);
      --alert-color: var(--color-error);
    }
  }
