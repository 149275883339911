:root {
    --bottom-curve-height: 150px
}

.hero {
    position: relative;

    @include breakpoint(sm, 'not all') {
        z-index: -1;
        + .section {
            margin-top: 0;
        }
    }

    &, &__bg, img {
        width: 100%;
        height: 100vmin;

        @include breakpoint(sm, 'not all') {
            min-height: 370px;
        }
    }

    &__bg {
        position: absolute;
        z-index: -1;

        &:before,
        &:after {
            @include pseudo;
            @include positionAbsolute;
            background: black;
            z-index: 1;
        }

        &:before {
            mix-blend-mode: overlay;
            opacity: .4;
        }

        &:after {
            opacity: .12;
            // this one will make it completely black if it's not supported, where the first one wont.
            @supports (mix-blend-mode: soft-light) {
                opacity: .9;
                mix-blend-mode: soft-light;
            }
        }
    }

    img {
        object-fit: cover;
        height: 100%;
        object-position: center center;
        filter: grayscale(1);
    }

    &:after {
        @include pseudo;
        width: 100%;
        bottom: calc((var(--bottom-curve-height) / 2) * -1);
        z-index: 10;
        height: var(--bottom-curve-height);
        background: radial-gradient(white 70%, transparent 70.6%);
        background-size: 150% 200%;
        background-repeat: no-repeat;
        background-position: center top;

        @include breakpoint(sm, 'not all') {
            background-size: 250% 200%;
        }
    }

    // Acutal content / slogan
    &__content {
        display: grid;
        height: calc(100% - var(--bottom-curve-height));
        grid-template-columns: var(--grid-columns);
        grid-template-rows: var(--header-height) calc(var(--header-height) / 2) 1fr 1fr 1fr;
    }

    &__title {
        @include flex;
        grid-area: 3 / 2 / 5 / -3;
        // font-size: var(--text-xxxxl);
        font-weight: var(--font-black);
        font-size: 16vmin;
        color: white;
        text-transform: uppercase;
        line-height: .8;
        margin-top: auto;
        word-spacing: 100vw;
        text-shadow: 0 0px 30px rgba(0,0,0,0.2);

        @include breakpoint(md, 'not all') { grid-area: 1 / 2 / 5 / -3; }
        @include breakpoint(sm, 'not all') { font-size: 14vmin; grid-area: 3 / 2 / 5 / -3; }
        @include breakpoint(xs, 'not all') { font-size: 11vmin; grid-area: 2 / 2 / 5 / -3; }

        span {
            $mb: 6px;
            display: block;
            color: black;
            position: relative;
            width: auto;
            mix-blend-mode: screen;
            margin-bottom: $mb;

            &:before {
                content: "";
                display: block;
                /* width: 100%; */
                background: white;
                height: 100%;
                z-index: -1;
                position: absolute;
                left: -100%;
                right: -10px;
                top: -$mb;
            }
        }
    }

    &__btn {
        @include flexCenter;
        grid-area: 5 / 4 / 6 / 7;
        height: 50px;
        width: 100%;
        // margin-left: auto;
        padding: .5em;
        max-width: 280px;

        @include breakpoint(sm, 'not all') {
            // grid-area: 5 / 2 / 6 / -1;
            display: none;
        }
    }
}
