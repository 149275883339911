:root {
    --footer-li-spacing: calc(var(--space-sm) / 2);
    --footer-grid: repeat(4, minmax(10px, .25fr)) repeat(4, minmax(10px, 1fr));

    @include breakpoint(md, 'not all') {
        --footer-grid: repeat(4, minmax(10px, .25fr)) repeat(3, minmax(10px, 1fr));
    }
    @include breakpoint(sm, 'not all') { --footer-grid: repeat(3, minmax(10px, 1fr)); }
    @include breakpoint(xs, 'not all') { --footer-grid: repeat(1, minmax(10px, 1fr)); }
}

.footer {
    display: grid;
    grid-template-columns: var(--footer-grid);
    grid-template-rows: auto auto;
    background: var(--color-black);
    color: var(--color-contrast-medium);

    &-col {
        padding: var(--space-xl) var(--space-md);
        @include breakpoint(sm, 'not all') { padding: var(--space-md); }
    }

    h1, h2, h3, h4, h5, h6 { color: white; }
    h3 {
        font-family: var(--font-primary);
        font-size: 1rem;
        font-weight: var(--font-bold);
        margin-bottom: var(--space-md);

        &:not(:first-child) { margin-top: var(--space-xl); }
    }

    &-col {
        overflow: hidden;
        width: 100%;

        &:first-of-type a {
            @include ellipsis(100%);
            display: inline-block;
        }
    }

    nav a {
        display: block;
        color: white;

        &:hover{ color: var(--color-primary); }
        + a { margin-top: var(--footer-li-spacing); }
    }

    li + li { margin-top: var(--footer-li-spacing); }

    .logo {
        grid-column: 2 / 5;
        margin-left: auto;
        margin-right: var(--space-lg);
        margin-top: var(--space-xl);

        @include breakpoint(sm, 'not all') {
            grid-column: 1 / -1;
            margin-left: var(--space-md);
            max-width: 200px;
        }

        svg { width: 100%; }
    }

    &__bottom {
        display: flex;
        grid-column: 1/-1;
        background: var(--color-contrast-high);
        font-size: 0.85em;
        justify-content: center;
        padding: var(--space-sm) 0 var(--space-xs);
        gap: 1em;

        > p:first-child { grid-column: 2 / 5; }
    }
}
