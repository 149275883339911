/* Forza */
@font-face {
    font-family: 'Forza';
    src: url('/fonts/Forza-Book.woff2') format('woff2'),
        url('/fonts/Forza-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Forza';
    src: url('/fonts/Forza-Bold.woff2') format('woff2'),
        url('/fonts/Forza-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Forza';
    src: url('/fonts/Forza-Black.woff2') format('woff2'),
        url('/fonts/Forza-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}


/* Gotham */
@font-face {
    font-family: 'Gotham';
    src: url('/fonts/GothamBlack.woff2') format('woff2'),
        url('/fonts/GothamBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/GothamBlack-Italic.woff2') format('woff2'),
        url('/fonts/GothamBlack-Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/GothamBold.woff2') format('woff2'),
        url('/fonts/GothamBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/GothamBold-Italic.woff2') format('woff2'),
        url('/fonts/GothamBold-Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/GothamMedium-Italic.woff2') format('woff2'),
        url('/fonts/GothamMedium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/GothamBook-Italic.woff2') format('woff2'),
        url('/fonts/GothamBook-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/GothamBook.woff2') format('woff2'),
        url('/fonts/GothamBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Gotham';
    src: url('/fonts/GothamMedium.woff2') format('woff2'),
        url('/fonts/GothamMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

