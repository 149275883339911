body {
    // font-size: var(--text-base-size);
    // font-family: var(--font-primary);
    // color: var(--color-text);
    font-weight: 100;
    overflow-x: hidden;
}

html,
body {
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

main,
.sidebar {
    min-height: 100vh;
    min-height: -moz-available;
    min-height: -webkit-fill-available;
}

main {
    > .section {
        margin: var(--space-xxxl) 0;

        @include breakpoint(sm, 'not all') {
            margin: var(--space-xxl) 0;
        }
    }
}

.footnote {
    font-size: 0.9em;
    margin: var(--space-sm) 0;
}

.container {
    &--hidden,
    &--visible {
        overflow: hidden;
    }
}

.hide {
    &--desktop {
        @include breakpoint(sm) {
            display: none !important;
        }
    }
    &--mob,
    &--mobile {
        @include breakpoint(sm, 'not all') {
            display: none !important;
        }
    }
}

//grid overlay
.grid-line {
    display: block;
    width: 1px;
    height: 100vh;
    background: rgba(153, 153, 153, 0.25);

    &__wrapper {
        display: grid;
        grid-template-columns: var(--grid-base-cols);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 200;
        pointer-events: none;
        width: 100%;
        height: 100vh;
    }
}
