:root {
    --c-grid-cols: 1fr 0.3fr;
    --c-grid-rows: minmax(auto, 1.5em) minmax(var(--space-xl), 1fr) auto auto;
    --c-padding: var(--space-md);
    --c-card-radius: var(--radius-sm);
    --c-font-size: 1.4em;
    --c-brand-col: 1;
    --c-brand-row: 1;
    --c-brand-bg: none;
    --c-thumb-col: 1 / -1;
    --c-thumb-row: 1 / span 4;
    --c-indicator-row: 3;
    --c-indicator-padding: 0 .25em 0 1em;
    --c-meta-row: 4;
    --c-meta-padding: var(--c-padding);
    --c-price-bg: none;
    --c-price-color: white;
    --c-price-size: 1.3em;
    --c-price-col: 2;
    --c-price-align: right;
    --c-title-font: var(--font-primary);
    --c-title-weight: var(--font-bold);
    --svg-margin: calc(var(--c-padding) / 1.5) var(--c-padding);
    --svg-fill: white;
    --svg-shadow: drop-shadow(2px 1px 5px rgba(0,0,0,0.5));
}

.card {
    display: grid;
    grid-template-rows: var(--c-grid-rows);
    position: relative;
    border-radius: var(--c-card-radius);
    border: 1px solid var(--color-contrast-low);
    height: auto;
    // max-height: calc(9 / 16 * 100%);
    z-index: 1;
    transition: 1.5s var(--ease-out);

    &[href]:hover  {
        transition: 0.4s var(--ease-out);
        transform: scale(1.015);
    }

    &--slider {
        --c-card-radius: var(--radius-md);
        --c-grid-cols: unset;
        --c-grid-rows: minmax(var(--space-xl), 1fr) auto auto auto;
        --c-thumb-row: 1 / -2;
        --c-brand-bg: var(--color-contrast-lower);
        --c-indicator-row: 2;
        --c-font-size: 1em;
        --c-title-font: var(--font-secondary);
        --c-title-weight: var(--font-black);
        --c-meta-row: 3;
        --c-meta-padding: .5em 1em;
        --c-price-bg: white;
        --c-price-color: black;
        --c-price-size: 1em;
        --c-price-col: 1;
        --c-price-align: left;
        --svg-margin: 0;
        --svg-fill: black;
        --svg-shadow: none;

        margin-left: auto;
        width: 90%;
    }

    &__brand {
        grid-column: var(--c-brand-col);
        grid-row: var(--c-brand-row);
        background: var(--c-brand-bg);

        &.s-stock__brand {
            max-width: 100px;
        }

        svg {
            max-height: 100%;
            margin: var(--svg-margin);
            fill: var(--svg-fill);
            filter: var(--svg-shadow);
        }
    }

    &__thumb {
        overflow: hidden;
        grid-column: var(--c-thumb-col);
        grid-row: var(--c-thumb-row);
        width: 100%;
        position: relative;
        z-index: -1;
        border-radius: var(--c-card-radius);
        transition: 1.5s var(--ease-out);

        a:hover & {
            transition: 0.4s var(--ease-out);
            filter: grayscale(1);
        }

        &, img {
            @include size(100%);
            max-width: 100%;
        }
        img {
            object-fit: cover;
        }

        &:before {
            @include pseudo;
            @include positionAbsolute;
            background: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 70%);
            mix-blend-mode: multiply;
            opacity: .7;
        }

    }

    &__indicator {
        grid-column: 1;
        grid-row: var(--c-indicator-row);
        width: max-content;
        background: white;
        padding: var(--c-indicator-padding);
        background: white;
        color: black;
        font-family: var(--font-secondary);
        font-weight: var(--font-black);
        font-size: var(--c-font-size);
        text-transform: uppercase;
        mix-blend-mode: screen;
    }

    &__meta {
        display: grid;
        grid-template-columns: var(--c-grid-cols);
        grid-column: 1;
        grid-row: var(--c-meta-row);
        padding: var(--c-meta-padding);
        color: white;

        * { color: currentColor; }
    }

    &__title {
        font-size: var(--c-font-size);
        font-family: var(--font-primary);
        font-weight: var(--font-bold);

        &--pre {
            font-weight: normal;
            text-transform: capitalize;
            display: block;
            margin-bottom: 0.2em;
        }
    }

    &__footer {
        @include flexMiddle;
        grid-column: 1;
        grid-row: 4;
        background: white;

        > * {
            height: 100%;
            padding: var(--space-sm);

            @include breakpoint(sm) { width: 50%; }
        }

        .card__brand {
            @include breakpoint(sm, 'not all') {
                width: 37%;

                ~ div { width: 63%; }
            }
        }

        svg {
            height: 100%;
            width: auto;
            max-height: 25px;
        }
    }

    &__price {
        grid-column: var(--c-price-col);
        font-size: var(--c-price-size);
        font-weight: var(--font-bold);
        color: var(--c-price-color);
        margin-bottom: 0;
        text-align: var(--c-price-align);
        background: var(--c-price-bg);
        align-self: flex-end;
    }

    &--stock &__price {
        border-left: 1px solid var(--color-primary);
    }
}
