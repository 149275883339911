.fade-in{
    &-enter-to,
    &-leave-from { opacity: 1; }

    &-enter-from,
    &-leave-to {opacity: 0; }

    &-enter-active,
    &-leave-active{
        transition: all 0.15s var(--ease-in);
    }
}
