:root {
    --t-col-width: 20vw;

    @include breakpoint(md, 'not all') { --t-col-width: 25vw; }
    @include breakpoint(sm, 'not all') { --t-col-width: 33.333vw; }
}

.grid-line{
    position: relative;
    &::after{
        content: '';
        display: block;
        width: 100%;
        left: 1px;
        height: 100%;
        position: absolute;
        background: white;
        opacity: 0;
    }
}

.change-page-bg {
    &-enter-to,
    &-leave-from { background: rgba(255, 255, 255, 0); }

    &-enter-from,
    &-leave-to { background: rgba(255, 255, 255, 1); }

    &-enter-active,
    &-leave-active{
        transition: all .25s var(--ease-in);
    }
}

.change-page {
    &-enter-to {
        .grid-line::after {
            width: calc(var(--t-col-width) - 1px);
            opacity: 0;
        }
    }

    &-enter-from {
        .grid-line::after {
            opacity: 1;
            width: calc(var(--t-col-width) - 1px);
            transform: translateX(0vw)
        }
    }

    &-leave-to {
        .grid-line::after {
            opacity: 1;
            width: calc(var(--t-col-width) - 1px);
            transform: translateX(0vw)
        }
    }

    &-leave-from {
        .grid-line::after {
            opacity: 1;
            width: 0;
            transform: translateX(0vw)
        }
    }

    &-enter-active,
    &-leave-active{
        .grid-line::after {
            transition: all .2s var(--ease-in);
        }
        transition: all .25s var(--ease-in);
    }
}

