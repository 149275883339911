:root {}

.s-filters {
  padding-right: var(--space-xl);

  h2 {
    font-size: 1.6rem;
  }
}

.s-filter {
  --slider-bg: var(--color-border);
  --slider-connect-bg: var(--color-primary);
  --slider-connect-bg-disabled: #9CA3AF;
  --slider-height: 6px;
  --slider-vertical-height: 300px;
  --slider-radius: 9999px;

  --slider-handle-bg: var(--color-primary);
  --slider-handle-border: 0;
  --slider-handle-width: 0.8em;
  --slider-handle-height: 0.8em;
  --slider-handle-radius: 9999px;
  --slider-handle-shadow: none;
  --slider-handle-shadow-active: none;
  --slider-handle-ring-width: 3px;
  --slider-handle-ring-color: #10B98130;

  // --slider-tooltip-font-size: 0.875rem;
  // --slider-tooltip-line-height: 1.25rem;
  // --slider-tooltip-font-weight: 600;
  // --slider-tooltip-min-width: 20px;
  // --slider-tooltip-bg: #10B981;
  // --slider-tooltip-bg-disabled: #9CA3AF;
  // --slider-tooltip-color: #fff;
  // --slider-tooltip-radius: 5px;
  // --slider-tooltip-py: 2px;
  // --slider-tooltip-px: 6px;
  // --slider-tooltip-arrow-size: 5px;
  // --slider-tooltip-distance: 3px;

  margin: var(--space-lg) 0;

  &__count {
    display: block;
    transform: translateY(calc(var(--space-lg) * -1));
    margin-top: -1em;
  }

  &__title {
    font-size: 1.1rem;
    font-family: var(--font-primary);
    font-weight: var(--font-bold);
    margin-bottom: var(--space-sm);
  }

  &__range {
    font-size: 0.85rem;
    color: var(--color-primary);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: var(--space-xs);
  }

  .slider-horizontal .slider-handle {
    transform: translateY(1px);
  }
}
