@import "@/assets/scss/mixins/_allMixins.scss";

.thumb-fallback {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-border);
    border-radius: 4px;

    svg {
        opacity: 0.15;
        max-width: 150px;
        margin: auto;
        * { fill: black; }
    }
}
